import React from 'react';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { chakra } from '@chakra-ui/react';

const StyledImage = chakra(GatsbyImage, {
  baseStyle: {
    gridRow: '1 / -1',
    borderRadius: '50%',
    border: '1px solid primaryLight',
  },
});

type AvatarProps = {
  /** Gatsby image object */
  image: {
    gatsbyImageData: IGatsbyImageData;
    alt: string;
  };
};

/** A simple avatar component. */
const Avatar = ({ image }: AvatarProps): JSX.Element => (
  <StyledImage image={image.gatsbyImageData} alt={image.alt} />
);

export default Avatar;
