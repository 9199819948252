import React, { useEffect, useState } from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { chakra } from '@chakra-ui/react';

/** Components matching Dato CMS Blocks */
import Section from '../components/ui/Section';
import Containers from '../components/ui/Containers';
import Header from '../components/ui/Header';
import Cta from '../components/ui/Cta';
import Card from '../components/ui/Card';
import SideBySide from '../components/ui/SideBySide';
import SideBySidePoints from '../components/ui/SideBySidePoints';
import FeatureImageSection from '../components/ui/FeatureImageSection';
import Testimonial from '../components/ui/Testimonial';
import SectionTitle from '../components/ui/SectionTitle';
import Team from '../components/ui/Team';
import ClientLogos from '../components/ui/ClientLogos';
import Faq from '../components/ui/Faq';
import ImageSection from '../components/ui/ImageSection';
import TextSection from '../components/ui/TextSection';
import VideoSection from '../components/ui/VideoSection';
import RelatedContent from '../components/ui/RelatedContentSection';
import CalendarSection from '../components/ui/CalendarSection';
import CardBlock from '../components/ui/CardBlock';

import AdminBar from '../components/ui/AdminBar';
import { generateUuid } from '../lib/Utilties';

type Props = {
  data: {
    datoCmsFeaturePage: {
      seoMetaTags: {
        id: string;
        children: any[];
        parent: any;
        tags: any[];
      };
      title: string;
      subtitle: string;
      slug: string;
      includeCtaButton: boolean;
      orderLabels: boolean;
      featureImage?: {
        video?: { thumbnailUrl: string; streamingUrl: string };
        gatsbyImageData?: IGatsbyImageData;
        alt?: string;
      };
      targetAudience: string;
      featuredMediaThumbTime: string;
      originalId: string;
      body: string;
    };
  };
  pageContext: string;
};

// Template for producing all the pages in the datoCMS page model
const FeaturePagesTemplate = ({ data, pageContext }: Props) => {
  const {
    seoMetaTags,
    title,
    subtitle,
    slug,
    includeCtaButton,
    orderLabels,
    featureImage,
    targetAudience,
    featuredMediaThumbTime,
    originalId,
  } = data.datoCmsFeaturePage;

  const Spacer = chakra('div', {
    baseStyle: {
      minHeight: '2rem',
      flexBasis: '100%',
    },
  });

  const [bodyData, setBodyData] = useState([]);

  // Add IDs for any DatoCMS blocks which don't have them already.
  useEffect(() => {
    const { body } = data.datoCmsFeaturePage;
    if (body) {
      body.forEach((block: any) => {
        if (!block.id) {
          block.id = generateUuid();
        }
      });
      setBodyData(body);
    }
  }, [data]);

  /** Small utility file to count the number of cards in a row. This will be used to adust the number of colums of cards in a section later in the cards component */
  const cardCount: number = data.datoCmsFeaturePage.body.filter(
    (element) => element.model.apiKey === 'card'
  ).length;

  /** Find the content that will go inside a section */

  const sectionContent = (array: any[], index: number) => {
    // Remove the first item from the array. nb this is always a section
    const newArray: any[] = array.slice(index + 1);

    // then we find the next instance of a section so we know when to finfish the next section
    const firstSection: number = newArray.findIndex(
      (block) => block.model.apiKey === 'section'
    );

    // Then we will make and return a new array which is only the interior of the section
    const sectionArray = newArray.slice(0, firstSection);

    if (firstSection === -1) {
      return newArray;
    }
    return sectionArray;
  };

  /** This logic checks to see if the page has and FAQ's. If it does it will then add some schema information to the head of the page */
  const filteredFaq = data.datoCmsFeaturePage.body.filter(
    (block) => block.model.apiKey === 'faq'
  );

  const formatedFaq = filteredFaq.map((faq) => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: { '@type': 'Answer', text: faq.answer },
  }));

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      mainEntity: [formatedFaq],
    },
  ];

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />
      <Helmet>
        <script type="application/ld+json">
          {filteredFaq.length > 1 && JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
      <AdminBar itemTypes="288617" originalId={originalId} />
      <Header
        title={title}
        subtitle={subtitle}
        includeCTA={includeCtaButton}
        includeOrder={orderLabels}
        image={featureImage}
        targetAudience={targetAudience}
        featuredMediaThumbTime={featuredMediaThumbTime}
        slug={slug}
        locale={pageContext.locale}
      />

      {bodyData.map((block: any, index: number, array: any) => {
        const sectionColor = block.model.apiKey === 'section' && block.color;

        return (
          <div key={block.id}>
            {block.model.apiKey === 'section' && (
              // Go through our page body array and first find each section which will contain body elements
              <Section
                variant={block.color}
                className={block.color}
                addPhoneCallToAction={block.addPhoneCallToAction}
                location={pageContext.locale}
              >
                <Containers mx="auto" maxWidth="maxWidth">
                  {sectionContent(array, index).map((block, index, array) => (
                    <React.Fragment key={index}>
                      {block.model.apiKey === 'call_to_action' && (
                        <Cta
                          title={block.title}
                          subtitle={block.subtitle}
                          buttonText={block.buttonText}
                          notLocal={block.notLocal}
                          externalLink={block.externalLink}
                          link={block.linkTo}
                          sectionColour={sectionColor}
                        />
                      )}

                      {block.model.apiKey === 'card' &&
                        index !== array.length - 1 && (
                          <CardBlock
                            block={block}
                            cardCount={cardCount}
                            lastCard={
                              index === array.length - 1
                                ? true
                                : array[index + 1].model.apiKey !== 'card'
                            }
                          />
                        )}
                      {block.model.apiKey === 'card' &&
                        index === array.length - 1 && (
                          <>
                            <CardBlock block={block} cardCount={cardCount} />
                            <Spacer />
                          </>
                        )}
                      {block.model.apiKey === 'client' && (
                        <ClientLogos
                          title={block.title}
                          clientLogos={block.clientLogos}
                          sectionColor={sectionColor}
                        />
                      )}

                      {block.model.apiKey === 'related_content' && (
                        <RelatedContent
                          title={block.heading}
                          contents={block.content}
                        />
                      )}

                      {block.model.apiKey === 'staffcard' && (
                        <Card>
                          <h3>{block.title}</h3>
                          <p>{block.text}</p>
                        </Card>
                      )}
                      {block.model.apiKey === 'sidebysidetext' && (
                        <SideBySide block={block} />
                      )}
                      {block.model.apiKey === 'sidebysidepoint' && (
                        <SideBySidePoints
                          title={block.title}
                          subtitle={block.subtitle}
                          heading1={block.point1Heading}
                          text1={block.point1Text}
                          heading2={block.point2Heading}
                          text2={block.point2Text}
                          heading3={block.point3Heading}
                          text3={block.point3Text}
                          image={block.image}
                          videoFile={block.videoFile}
                          alt={block.alt}
                          imageAlignment={block.imageAlignment}
                        />
                      )}
                      {block.model.apiKey === 'feature_image_section' && (
                        <FeatureImageSection
                          title={block.title}
                          subTitle={block.subTitle}
                          image={block.image}
                          buttonText={block.buttonText}
                          link={block.link}
                          imageAbove={block.imageAbove}
                          epigraph={block.epigraph}
                        />
                      )}
                      {block.model.apiKey === 'image_section' && (
                        <ImageSection image={block.image} />
                      )}
                      {block.model.apiKey === 'faq' && (
                        <Faq
                          index={index}
                          id={block.linkHash}
                          question={block.question}
                          answer={block.answer}
                          lastCard={
                            index === array.length - 1
                              ? true
                              : array[index + 1].model.apiKey !== 'faq'
                          }
                        />
                      )}

                      {block.model.apiKey === 'resuable_component' &&
                        block.component.content.map((block, index, array) => {
                          if (
                            block.model.apiKey === 'card' &&
                            index !== array.length - 1
                          )
                            return (
                              <CardBlock
                                block={block}
                                cardCount={
                                  array.filter(
                                    (element) => element.model.apiKey === 'card'
                                  ).length
                                }
                                lastCard={
                                  index === array.length - 1
                                    ? true
                                    : array[index + 1].model.apiKey !== 'card'
                                }
                              />
                            );
                          if (
                            block.model.apiKey === 'card' &&
                            index === array.length - 1
                          )
                            return (
                              <>
                                <CardBlock
                                  block={block}
                                  cardCount={
                                    array.filter(
                                      (element) =>
                                        element.model.apiKey === 'card'
                                    ).length
                                  }
                                  lastCard={
                                    index === array.length - 1
                                      ? true
                                      : array[index + 1].model.apiKey !== 'card'
                                  }
                                />
                                <Spacer />
                              </>
                            );
                        })}

                      {block.model.apiKey === 'testimonial' && (
                        <Containers>
                          <Testimonial
                            heading={block.heading}
                            testimonial={block.testimonialText1}
                            reviewer={block.reviewer}
                            role={block.role}
                            testimonial2={block.testimonialText2}
                            reviewer2={block.reviewer2}
                            role2={block.role2}
                          />
                        </Containers>
                      )}
                      {block.model.apiKey === 'team' && (
                        <Team
                          title={block.title}
                          teamMembers={block.teamMembers}
                        />
                      )}
                      {block.model.apiKey === 'section_title' && (
                        <SectionTitle
                          sectionTitle={block.sectionTitleText}
                          subTitle={block.subTitle}
                          highlight={block.highlight}
                        />
                      )}
                      {block.model.apiKey === 'text' && (
                        <Containers>
                          <TextSection text={block.text} />
                        </Containers>
                      )}
                      {block.model.apiKey === 'video' && (
                        <VideoSection
                          id={block.id}
                          video={block.videoFile.video.streamingUrl}
                          duration={block.videoFile.video.duration}
                          createdAt={block.videoFile.createdAt}
                          thumbnail={block.videoFile.video.thumbnailUrl}
                          videoThumbnailTime={block.videoThumbnailTime}
                          title={block.title}
                          captions={block.captions?.url}
                        />
                      )}
                      {block.model.apiKey === 'calendar' && (
                        <CalendarSection
                          title={block.title}
                          meetingUrl={block.meetingUrl}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Containers>
              </Section>
            )}
          </div>
        );
      })}
    </>
  );
};

export default FeaturePagesTemplate;

export const query = graphql`
  query FeaturePagesPageQuery($slug: String!, $locale: String!) {
    datoCmsFeaturePage(locale: { eq: $locale }, slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      slug
      originalId
      featuredMediaThumbTime
      includeCtaButton
      orderLabels
      featureImage {
        alt
        video {
          streamingUrl
          thumbnailUrl(format: jpg)
          muxPlaybackId
          mp4Url(res: medium)
        }
        gatsbyImageData(
          width: 700
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { fm: "auto", auto: "compress", fit: "clamp" }
        )
      }
      treeParent {
        slug
        treeParent {
          slug
        }
      }
      targetAudience

      body {
        ... on DatoCmsCallToAction {
          model {
            apiKey
          }
          id
          title
          subtitle
          buttonText
          notLocal
          externalLink

          linkTo {
            ... on DatoCmsLandingPage {
              slug
              __typename
            }
            ... on DatoCmsFeaturePage {
              slug
              __typename
              treeParent {
                slug
                __typename
                treeParent {
                  slug
                  __typename
                }
              }
            }
          }
        }
        ... on DatoCmsResuableComponent {
          model {
            apiKey
          }
          id
          component {
            content {
              model {
                apiKey
              }
              id
              text
              title
              icon {
                alt
                gatsbyImageData(
                  layout: FIXED
                  height: 125

                  placeholder: BLURRED
                  forceBlurhash: false
                )
              }
              linkCta
              externalLinkUrl
              linkTo {
                ... on DatoCmsArticle {
                  __typename
                  slug
                }
                ... on DatoCmsLandingPage {
                  __typename
                  slug
                }
                ... on DatoCmsPositionsAvailable {
                  __typename
                  slug
                }
                ... on DatoCmsFeaturePage {
                  slug
                  __typename
                  treeParent {
                    slug
                    treeParent {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
        ... on DatoCmsCard {
          model {
            apiKey
          }
          id
          title
          linkTo {
            ... on DatoCmsArticle {
              __typename
              slug
            }
            ... on DatoCmsLandingPage {
              __typename
              slug
            }
            ... on DatoCmsPositionsAvailable {
              __typename
              slug
            }
            ... on DatoCmsFeaturePage {
              slug
              __typename
              treeParent {
                slug
                treeParent {
                  slug
                }
              }
            }
          }
          icon {
            alt
            gatsbyImageData(
              layout: FIXED
              height: 250

              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          videoFile {
            video {
              streamingUrl
            }
            url
          }
          videoCheck
          text
          linkCta
          externalLinkUrl
          linkTo {
            ... on DatoCmsArticle {
              __typename
              slug
            }
            ... on DatoCmsLandingPage {
              __typename
              slug
            }
            ... on DatoCmsPositionsAvailable {
              __typename
              slug
            }
            ... on DatoCmsFeaturePage {
              slug
              __typename
              treeParent {
                slug
                treeParent {
                  slug
                }
              }
            }
          }
        }
        ... on DatoCmsClient {
          model {
            apiKey
          }

          title
          subtitle

          clientLogos {
            id
            logo {
              alt
              gatsbyImageData(
                width: 250
                height: 70
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: { sat: -100, padLeft: 40, padRight: 40 }
              )
            }
          }
        }

        ... on DatoCmsFaq {
          model {
            apiKey
          }
          id
          answer
          question
          linkHash
        }
        ... on DatoCmsSection {
          model {
            apiKey
          }
          id
          color
          addPhoneCallToAction
        }
        ... on DatoCmsSectionTitle {
          model {
            apiKey
          }
          id
          sectionTitleText
          subTitle
          highlight
        }
        ... on DatoCmsText {
          model {
            apiKey
          }
          id
          text
        }
        ... on DatoCmsSidebysidetext {
          model {
            apiKey
          }
          id
          title
          subtitle
          text
          linkCta
          includeOrder
          orderLink
          orderButtonText
          linkTo {
            ... on DatoCmsArticle {
              slug
              __typename
            }
            ... on DatoCmsLandingPage {
              slug
              __typename
            }
            ... on DatoCmsFeaturePage {
              slug
              __typename
              treeParent {
                slug
                treeParent {
                  slug
                }
              }
            }
          }
          imageAlignment
          image {
            alt
            gatsbyImageData(
              width: 400
              height: 400
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                auto: "compress"
                fit: "contain"
                dpr: 2
                w: "400"
              }
            )
          }
        }
        ... on DatoCmsSidebysidepoint {
          model {
            apiKey
          }
          id
          title
          subtitle
          point1Heading
          point2Heading
          point3Heading
          point1Text
          point2Text
          point3Text
          imageAlignment
          videoFile {
            video {
              streamingUrl
            }
            url
          }
          image {
            alt
            gatsbyImageData(
              width: 500
              height: 500
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                auto: "compress"
                fit: "contain"
                dpr: 2
                w: "500"
              }
            )
            fluid(
              maxWidth: 1000
              maxHeight: 1000
              imgixParams: { auto: "compress", fit: "clamp", dpr: 2, w: "1000" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsFeatureImageSection {
          model {
            apiKey
          }
          id

          image {
            alt
            gatsbyImageData(
              layout: CONSTRAINED
              height: 460

              imgixParams: {
                fm: "png"
                auto: "compress"
                fit: "contain"
                dpr: 2
              }
            )
          }
          epigraph
          title
          subTitle
          buttonText
          imageAbove
          link: linkTo {
            ... on DatoCmsArticle {
              slug
              __typename
            }
            ... on DatoCmsLandingPage {
              slug
              __typename
            }
            ... on DatoCmsFeaturePage {
              slug
              __typename
              treeParent {
                slug
                __typename
                treeParent {
                  slug
                  __typename
                }
              }
            }
          }
        }
        ... on DatoCmsImageSection {
          model {
            apiKey
          }
          id
          image {
            alt
            gatsbyImageData(
              width: 1000
              layout: CONSTRAINED
              imgixParams: { auto: "compress", fit: "clamp", dpr: 2, w: "2000" }
            )
          }
        }
        ... on DatoCmsTestimonial {
          model {
            apiKey
          }
          id
          heading
          testimonialText1
          testimonialText2
          reviewer
          reviewer2
          role
          role2
        }
        ... on DatoCmsVideo {
          id
          title
          videoThumbnailTime
          captions {
            url
          }
          meta {
            createdAt
          }
          videoFile {
            createdAt
            video {
              duration
              streamingUrl
              thumbnailUrl(format: jpg)
              muxPlaybackId
              mp4Url(res: medium)
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCalendar {
          id
          title
          meetingUrl
          model {
            apiKey
          }
        }
        ... on DatoCmsRelatedContent {
          id
          heading
          content {
            ... on DatoCmsArticle {
              id
              title
              slug
              featuredMedia {
                alt
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  forceBlurhash: false
                  aspectRatio: 1.5
                  imgixParams: {
                    fm: "jpg"
                    auto: "compress"
                    crop: "faces"
                    fit: "cover"
                    dpr: 2
                    w: "700"
                  }
                )
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTeam {
          model {
            apiKey
          }
          id
          title

          teamMembers {
            id
            name
            bio
            jobTitle
            url
            image {
              alt
              gatsbyImageData(
                height: 320
                layout: CONSTRAINED
                placeholder: BLURRED
                forceBlurhash: false
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  crop: "faces"
                  dpr: 2
                  fit: "crop"
                }
              )
            }
          }
        }
      }
    }
  }
`;
