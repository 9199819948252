const ctaLink = (rawLink) => {
  const link = rawLink[0] || null;

  if (link === null) {
    return;
  }
  if (link.__typename === 'DatoCmsArticle') {
    return `/blog/${link.slug}`;
  }
  if (link.__typename === 'DatoCmsFeaturePage') {
    let path = '';

    if (link.treeParent) {
      if (link.treeParent.treeParent) {
        path = `${link.treeParent.treeParent.slug}/${link.treeParent.slug}/${link.slug}/`;
      } else {
        path = `${link.treeParent.slug}/${link.slug}/`;
      }
    } else {
      path = `${link.slug}/`;
    }

    return path;
  }
  if (link.__typename === 'DatoCmsLandingPage') {
    return `/info/${link.slug}`;
  }
};

export default ctaLink;
