import React, { useState } from 'react';
import Helmet from 'react-helmet';
import ReactPlayer from 'react-player/file';
import { Box, Button, chakra, Img } from '@chakra-ui/react';
import { Link } from 'gatsby';
import { H2 } from '../styles/HeadingStyles';
import pb from '../../images/playButton.png';
import secondsToDuration from '../../lib/secondsToDuration';

const PlaybuttonStyles = chakra(Img, {
  baseStyle: {
    animation: 'pulse 3s infinite',
    margin: '0 auto',
    display: 'table',
    animationDirection: 'alternate',
    WebkitAnimationName: 'pulse',
    AnimationName: 'pulse',
    '@-webkit-keyframes pulse': {
      '0%': {
        WebkitTransform: 'scale(1)',
      },
      '50%': {
        WebkitTransform: 'scale(1.2)',
      },
      '100%': {
        WebkitTransform: 'scale(1)',
      },
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: 'scale(1.2)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  },
});

type Props = {
  streamingUrl: string;
  thumbnailUrl: string;
  featuredMediaThumbTime?: number | any;
  title: string;
  captions?: string;
  createdAt?: string;
  duration?: string;
  bookDemo?: boolean;
};

/** A play button that displays on all videos */
const PlayButton = () => (
  <Box
    width={['40px', '110px']}
    height={['40px', '110px']}
    mt={['60px', '180px']}
  >
    <PlaybuttonStyles src={pb} alt="Play Button" />
  </Box>
);

/** A component used for playing videos via ReactPlayer */
const VideoPlayer = ({
  streamingUrl,
  thumbnailUrl,
  featuredMediaThumbTime,
  title,
  captions,
  createdAt,
  duration,
  bookDemo,
}: Props) => {
  const [playing, setPlaying] = useState(false);
  const [ended, setEnded] = useState(false);

  // This is gonna our percent buckets ( 10%-90% )
  // var divisor = 10;
  // We're going to save our players status on this object.
  const videos_status = {};
  // This is the function that is gonna handle the event sent by the player listeners
  function eventHandler(e, url, time) {
    const dataLayer = window.dataLayer || [];
    switch (e) {
      // This event type is sent everytime the player updated it's current time,
      // We're using for the % of the video played.
      case 'timeupdate':
        // Let's set the save the current player's video time in our status object
        videos_status[url].current = Math.round(time);
        // We just want to send the percent events once
        var pct = Math.floor(
          (100 * videos_status[url].current) / e.target.duration
        );
        for (const j in videos_status[url]._progress_markers) {
          if (pct >= j && j > videos_status[url].greatest_marker) {
            videos_status[url].greatest_marker = j;
          }
        }
        // current bucket hasn't been already sent to GA?, let's push it to GTM
        if (
          videos_status[url].greatest_marker &&
          !videos_status[url]._progress_markers[
            videos_status[url].greatest_marker
          ]
        ) {
          videos_status[url]._progress_markers[
            videos_status[url].greatest_marker
          ] = true;
          dataLayer.push({
            event: 'video',
            gaEventCategory: 'HTML5 Video',
            gaEventAction: `Progress ${videos_status[url].greatest_marker}%`,
            // We are using sanitizing the current video src string, and getting just the video name part
            gaEventLabel: url,
          });
        }
        break;
      // This event is fired when user's click on the play button
      case 'play':
        setPlaying(true);
        setEnded(false);
        dataLayer.push({
          event: 'video',
          gaEventCategory: 'HTML5 Video',
          gaEventAction: 'Play',
          gaEventLabel: url,
        });
        break;
      // This event is fired when user's click on the pause button
      case 'pause':
        setEnded(true);
        dataLayer.push({
          event: 'video',
          gaEventCategory: 'HTML5 Video',
          gaEventAction: 'Pause',
          gaEventLabel: url,
          gaEventValue: url,
        });
        break;
      // If the user ends playing the video, an Finish video will be pushed ( This equals to % played = 100 )
      case 'ended':
        setEnded(true);
        dataLayer.push({
          event: 'video',
          gaEventCategory: 'HTML5 Video',
          gaEventAction: 'Finished',
          gaEventValue: url,
        });
        break;
      default:
        break;
    }
  }

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      name: title,
      description: "This is an overview of SaferMe's contact tracing solution",
      thumbnailUrl,
      uploadDate: createdAt,
      duration: secondsToDuration(duration),
      interactionCount: '544',
      contentUrl: streamingUrl,
    },
  ];

  const PlayerContainer = chakra('div', {
    baseStyle: {
      position: 'relative',
      paddingTop: '56.25%',
      margin: '0rem auto 2.5rem',
      '.player': {
        position: 'absolute',
        top: '0',
        left: '0',
      },
      '.react-player__preview': {
        background: '#333',
        backgroundImage: `linear-gradient( rgba(34, 56, 68, 0.8),rgba(34, 56, 68, 0.8)), url(${thumbnailUrl}?time=${featuredMediaThumbTime})`,
      },
    },
  });

  if (bookDemo) {
    return (
      <PlayerContainer>
        <Helmet>
          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>
        </Helmet>
        {!playing && (
          <Box
            color="#fff"
            width="100%"
            textAlign="center"
            sx={{
              position: 'absolute',
              zIndex: '1111111111',
              top: 0,
              height: '50%',
              display: 'flex',
              justifyContent: 'center',
            }}
            mt={['40px', 0]}
          >
            <H2
              maxWidth="900px"
              margin="auto"
              fontSize={[3, 5, 8]}
              px={[5, 0]}
              mt={[0, 4]}
              sx={{ position: 'absolute', bottom: 10 }}
            >
              {title}
            </H2>
          </Box>
        )}
        {ended && (
          <Box
            color="#fff"
            width="100%"
            bg="rgba(34, 56, 68, 0.95)"
            textAlign="center"
            sx={{
              position: 'absolute',
              zIndex: '1111111111',
              top: 0,
              height: '90%',
            }}
            pt={['40px', '17%']}
          >
            <H2 fontSize={[3, 5, 8]} maxWidth="755px" margin="auto">
              Ready to move forward? Book a demo
            </H2>
            <Link to="/pricing">
              <Button variant="primaryDark" size="lg">
                Book a demo
              </Button>
            </Link>
          </Box>
        )}
        <ReactPlayer
          className="player"
          config={{
            file: {
              forceVideo: true,
              hlsVersion: '0.12.4',
              attributes: {
                poster: `${thumbnailUrl}?time=${featuredMediaThumbTime}`,
                disablePictureInPicture: false,
                crossOrigin: 'true',
                autoplay: false,
              },
              tracks: [
                {
                  kind: 'subtitles',
                  src: captions,
                  srcLang: 'en',
                  default: false,
                },
              ],
            },
          }}
          url={streamingUrl}
          controls
          width="100%"
          height="100%"
          light
          playIcon={<PlayButton />}
          playing
          onPlay={() => eventHandler('play', streamingUrl, 0)}
          onPause={() => {
            eventHandler('pause', streamingUrl, 0);
          }}
          onEnded={() => {
            eventHandler('ended', streamingUrl, 0);
          }}
          onDuration={(e) => {
            console.log('duration -', e);
          }}
        />
      </PlayerContainer>
    );
  }
  return (
    <PlayerContainer>
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
      {!playing && (
        <Box
          color="#fff"
          width="100%"
          textAlign="center"
          sx={{
            position: 'absolute',
            zIndex: '1111111111',
            top: 0,
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
          }}
          mt={['40px', 0]}
        >
          <H2
            maxWidth="755px"
            margin="auto"
            fontSize={[3, 5, 8]}
            px={[5, 0]}
            mt={[0, 4]}
            sx={{ position: 'absolute', bottom: 20 }}
          >
            {title}
          </H2>
        </Box>
      )}
      <ReactPlayer
        className="player"
        config={{
          file: {
            forceVideo: true,
            hlsVersion: '0.12.4',
            attributes: {
              poster: `${thumbnailUrl}?time=${featuredMediaThumbTime}`,
              disablePictureInPicture: false,
              crossOrigin: 'true',
              autoplay: false,
            },
            tracks: [
              {
                kind: 'subtitles',
                src: captions,
                srcLang: 'en',
                default: false,
              },
            ],
          },
        }}
        url={streamingUrl}
        controls
        width="100%"
        height="100%"
        light={`${thumbnailUrl}?time=${featuredMediaThumbTime}`}
        playIcon={<PlayButton />}
        playing
        onPlay={() => eventHandler('play', streamingUrl, 0)}
        onPause={() => {
          eventHandler('pause', streamingUrl, 0);
        }}
        onEnded={() => {
          eventHandler('ended', streamingUrl, 0);
        }}
        onDuration={(e) => {
          console.log('duration -', e);
        }}
      />
    </PlayerContainer>
  );
};

export default VideoPlayer;
