import React, { useEffect } from 'react';
import { useScript } from '../../hooks/useScripts';
import logEvent from '../../lib/logEvent';

type Props = {
  meetingUrl?: string;
};

/** A hubspot meeting calendar component defaults to us calendar */
const Cal = ({
  meetingUrl = 'https://meetings.safer.me/meetings/saferme/introduction-us?embed=true',
}: Props) => {
  useScript(meetingUrl);
  useEffect(() => {
    logEvent('Sign up', 'Viewed Meeting Booker');
  }, []);

  return (
    <div
      style={{ minHeight: '100vh', maxWidth: '830px' }}
      className="meetings-iframe-container"
    >
      <iframe
        title="meetingForm"
        src={meetingUrl}
        frameBorder="0"
        width="100%"
        className="meetings-iframe-container"
        style={{ height: '80vh' }}
        loading="lazy"
      />
    </div>
  );
};

export default Cal;
