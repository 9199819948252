import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import {
  chakra,
  Box,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Accordion,
  AccordionPanel,
} from '@chakra-ui/react';
import { useToggle } from '../../hooks/useToggle';
import { H3 } from '../styles/HeadingStyles';
import { adjustVideoSize } from '../../lib/Utilties';

const MyAccordion = chakra(Accordion, {
  baseStyle: {
    textAlign: 'left',
    flex: '1 1 100%',
  },
});

const MyAccordionItem = chakra(AccordionItem, {
  baseStyle: {
    border: '0',
  },
});

const MyAccordionButton = chakra(AccordionButton, {
  baseStyle: {
    fontSize: ['2', '6'],
    background: 'transparent',
    color: 'darkPrimary',
    border: '0',
    textAlign: 'left',
    padding: '0',
    _hover: {
      cursor: 'pointer',
      bg: 'transparent',
    },
    expanded: {
      borderColor: 'highlights.5',
    },
    display: 'flex',
    flex: '1 1 100%',
  },
});

const MyAccordionPanel = chakra(AccordionPanel, {
  baseStyle: {
    flex: '1 1 100%',
    '&.show': {
      display: 'block',
    },
    p: {
      fontSize: ['1rem', '25px'],
      lineHeight: '35px',
      marginBottom: '32px',
    },
    a: {
      color: 'primary',
      textDecoration: 'underline',
    },
  },
});

type Props = {
  question: string;
  answer: string;
  index: number;
  id: string;
  lastCard: boolean;
};

function Faq({ question, answer, index, id, lastCard }: Props) {
  // If there is a query sting it can be used to jump to the correct part in the page
  const { hash } = useLocation();

  const { toggle, isToggled, setToggle } = useToggle(false);
  const unHash = hash ? hash.replace(/^#/, '') : '';

  useEffect(() => {
    if (unHash === id && unHash !== '') {
      setToggle(true);
    }
    adjustVideoSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyAccordion
      allowMultiple
      allowToggle
      id={id}
      mb={lastCard ? [8, 32] : [6]}
      sx={{ borderBottom: '1px solid', borderBottomColor: 'secondary' }}
      maxWidth="maxWidth"
    >
      <MyAccordionItem>
        <H3
          as="h3"
          fontSize={[4, 8]}
          lineHeight={[2, 6]}
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
          aria-expanded={isToggled ? 'true' : 'false'}
          aria-controls={`question-${index}`}
          id={`answer-${index}`}
          mb={[6]}
          sx={{ flex: '1 1 100%' }}
          maxWidth="maxWidth"
        >
          <MyAccordionButton>
            <Box flex="1" textAlign="left">
              {question}
            </Box>
            <AccordionIcon textAlign="right" />
          </MyAccordionButton>
        </H3>
        <MyAccordionPanel
          id={`question-${index}`}
          className={!isToggled ? 'hide' : 'show'}
          dangerouslySetInnerHTML={{ __html: answer }}
          aria-labelledby={`answer-${index}`}
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        />
      </MyAccordionItem>
    </MyAccordion>
  );
}

Faq.propTypes = {
  answer: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  lastCard: PropTypes.bool,
  question: PropTypes.string,
};

export default Faq;
