import React from 'react';
import { Flex } from '@chakra-ui/react';
import Bio from './Bio';
import { H2 } from '../styles/HeadingStyles';

type TeamProps = {
  title: string;
  teamMembers: [
    {
      id: string;
      image: object;
      name: string;
      bio: string;
      jobTitle: string;
      url: string;
    }
  ];
};

/** Display team members here. Shows an array of user bios which have been selected in CMS */
const Team = ({ title, teamMembers }: TeamProps): JSX.Element => (
  <Flex flexWrap="wrap" flexBasis="100%" mb={[8, '5.556rem']}>
    <H2>{title}</H2>
    <Flex flexWrap="wrap" mx={[-5, -7]} mb={[16, 32]} alignItems="flex-start">
      {teamMembers.map((teamMember) => (
        <React.Fragment key={teamMember.id}>
          <Bio
            image={teamMember.image && teamMember.image}
            name={teamMember.name}
            bio={teamMember.bio}
            jobTitle={teamMember.jobTitle}
            url={teamMember.url}
          />
        </React.Fragment>
      ))}
    </Flex>
  </Flex>
);

export default Team;
