import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import { H2, SubHeading, H5 } from '../styles/HeadingStyles';

const PointHeading = chakra(SubHeading, {
  baseStyle: {
    marginBottom: '4px',
  },
});

type Props = {
  /** Heading for the side by side section */
  title: string;
  /** subtitle for the side by side section. Appears above the title in green and caps */
  subtitle: string;
  /** HEading for a point */
  heading1: string;
  /** HEading for a point */
  heading2: string;
  /** HEading for a point */
  heading3: string;
  /** text for for a point below heading */
  text1: string;
  /** text for for a point below heading */
  text2: string;
  /** text for for a point below heading */
  text3: string;
  /** This will add a link under the main text. Eg explore features  */
  alt?: string;
  /** Image object must be fluid  */
  image: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
  videoFile: {
    video: {
      streamingUrl: string;
    };
    url: string;
  };
  /** Changes which side the image will show on */
  imageAlignment?: string;
};
/** This is similar ot the side by side component but rather than showing plain text it will show a bullet point list */
const SideBySidePoints = ({
  image,
  videoFile,
  title = 'The best safety app',
  subtitle,
  imageAlignment,
  heading1,
  heading2,
  heading3,
  text1,
  text2,
  text3,
}: Props) => (
  <Flex mb={[6, '5.556rem']} maxWidth="maxWidth" mx="auto" flexBasis="100%">
    <Flex
      flexWrap="wrap"
      justifyContent="space-around"
      // alignItems="left"
      flexBasis="100%"
      flexDirection={imageAlignment === 'Left' ? ['row', 'row-reverse'] : 'row'}
    >
      <Box
        flex="1 1 300px"
        mb={[7, 0]}
        mr={imageAlignment === 'Right' && [0, 20]}
      >
        <H5 as="p">{subtitle}</H5>
        <H2 mb={[16]}>{title}</H2>
        <Box mb="4">
          <PointHeading fontSize="large">{heading1}</PointHeading>
          <Text fontSize="large">{text1}</Text>
        </Box>
        <Box mb="4">
          <PointHeading fontSize="large">{heading2}</PointHeading>
          <Text fontSize="large">{text2}</Text>
        </Box>
        <Box mb="4">
          <PointHeading fontSize="large">{heading3}</PointHeading>
          <Text fontSize="large">{text3}</Text>
        </Box>
      </Box>
      <Box
        flex="1 1 300px"
        alignSelf="flex-end"
        mr={imageAlignment === 'Left' && [0, 'auto']}
        order={[-1, 0]}
        mb={[7, 0]}
      >
        {image && (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt || 'no image'}
            objectFit="contain"
          />
        )}
        {videoFile && (
          <ReactPlayer
            className="player"
            url={videoFile.video.streamingUrl}
            width="70%"
            controls
          />
        )}
      </Box>
    </Flex>
  </Flex>
);

SideBySidePoints.defaultProps = {
  alt: 'The video instructions app',
};

export default SideBySidePoints;
