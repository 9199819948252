import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { H2, H4, H5 } from '../styles/HeadingStyles';
import ctaLink from '../../lib/ctaLink';

type Props = {
  /** Image object for gatsby image from DatoCMS */
  image?: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  /** A epigraph sting for the component. From DatoCMS */
  epigraph?: string;
  /** A subtitle sting for the component. From DatoCMS */
  subTitle?: string;
  /** A title sting for the component. From DatoCMS */
  title: string;
  /** A switch to allow feature image to be display above the heading, subtitle and CTA. Defaults to image below. From DatoCMS */
  imageAbove?: boolean;
  /** Text for the button From DatoCMS */
  buttonText?: string;
  /** URL for CTA link. From DatoCMS */
  link?: string;
};

/** Component for the featured image section. Mainly included on the Feature page template */
const FeatureImageSection = ({
  title,
  subTitle,
  image,
  buttonText,
  link,
  imageAbove,
  epigraph,
}: Props) => (
  <Box flexBasis="100%">
    <Box mb={[7, '5.556rem']}>
      <H5 as="p">{epigraph}</H5>
      <H2>{title}</H2>
      <H4 as="p">{subTitle}</H4>
    </Box>

    <Box mx="auto" order={imageAbove ? -1 : 0} mb={[7, 16]}>
      <GatsbyImage
        image={image.gatsbyImageData}
        objectFit="contain"
        alt={image.alt}
        style={{ margin: '1rem', maxHeight: '450px' }}
      />
    </Box>

    {link.length === 1 && (
      <a href={ctaLink(link)}>
        <Button
          px="24px"
          py="10px"
          variant="primary"
          minWidth={['100%', '100%', 0]}
        >
          {buttonText}
        </Button>
      </a>
    )}
  </Box>
);

export default FeatureImageSection;
