import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Flex, Box, Text, Button, Link } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import { H2, H5 } from '../styles/HeadingStyles';
import CtaLink from './CtaLink';

type Props = {
  block: {
    /** Heading for the side by side section */
    title: string;
    /** subtitle for the side by side section. Appears above the title in green and caps */
    subtitle: string;
    /** text for the side by side section */
    text: string;
    /** This will add a link under the main text. Eg explore features  */
    cta?: string;
    /** Alt tag */
    alt: string;
    /** Image object must be fluid  */
    image?: IGatsbyImageData;
    /** Image alignment */
    imageAlignment: string;
    /** Link data */
    link?: {};
    includeOrder?: boolean;
    orderLink?: string;
    orderButtonText?: string;
  };
};

/** Component for showing an image next to a colum of text. */
const SideBySide = ({ block }: Props) => (
  <Flex
    flexWrap="wrap"
    justifyContent="space-around"
    flexBasis="100%"
    flexDirection={
      block.imageAlignment === 'Left' ? ['row', 'row-reverse'] : 'row'
    }
    mb={[8, 9]}
  >
    <Box flex="1 1 300px" mr={block.imageAlignment === 'Right' && [0, 8]}>
      <H5 as="p">{block.subtitle}</H5>
      <H2 mb={[5, 8]}>{block.title}</H2>

      <Text as="p" mb={[5]}>
        {block.text}
      </Text>
      {block.cta && <CtaLink cta={block.cta} link={block.link} mt="6" />}
      {block.includeOrder && (
        <Button>
          <Link href={block.orderLink}>{block.orderButtonText}</Link>
        </Button>
      )}
    </Box>
    <Box
      flex="1 1 300px"
      alignSelf="flex-end"
      order={[-1, 0, 0]}
      mb={[7, 7, 0]}
      align="center"
      mr={block.imageAlignment === 'Left' && [0, 8, 8]}
    >
      {block.image && (
        <GatsbyImage
          image={block.image.gatsbyImageData}
          alt={block.alt}
          objectFit="contain"
        />
      )}
    </Box>
  </Flex>
);

SideBySide.defaultProps = {
  image: {},
  alt: 'The best safety app',
};

export default SideBySide;
