import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { H2, SubHeading } from '../styles/HeadingStyles';

type Props = {
  contents: [
    {
      title: string;
      id: string;
      slug: string;
      featuredMedia?: {
        alt: string;
        gatsbyImageData: IGatsbyImageData;
      };
    }
  ];
  title?: string;
};
const RelatedContent = ({ contents, title }: Props): JSX.Element => (
  <Box flexBasis="100%" mb={[16, '5.556rem']} mx="auto" maxWidth="maxWidth">
    <Box mx="auto">
      <H2 mb={[8, 8, 7]}>{title}</H2>
    </Box>

    <Flex flexWrap="wrap">
      {contents.map((content, index, array) => (
        <Box
          mr={index === array.length - 1 ? [0, 0] : [0, 8]}
          mb={8}
          sx={{ flex: '1 1 300px' }}
          key={content.id}
        >
          <a href={`/blog/${content.slug}`}>
            {content.featuredMedia && (
              <GatsbyImage
                image={content.featuredMedia.gatsbyImageData}
                alt={content.featuredMedia.alt}
                style={{ marginBottom: '1rem' }}
              />
            )}
            <SubHeading as="h3">{content.title}</SubHeading>
            <Button variant="primary" mt="6" size="lg">
              Read more
            </Button>
          </a>
        </Box>
      ))}
    </Flex>
  </Box>
);

export default RelatedContent;
