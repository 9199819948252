import React from 'react';
import { Box } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type Props = {
  image: {
    alt?: string;
    gatsbyImageData: IGatsbyImageData;
  };
};
/** Component for the featured image section. Mainly included on the Feature page template */
const ImageSection = ({ image }: Props) => (
  <Box flexBasis="100%" mx={[5, 0]} mb={[7, 16]}>
    <GatsbyImage
      image={image.gatsbyImageData}
      alt={image.alt}
      objectFit="contain"
      style={{ flex: '0 0 1110px' }}
    />
  </Box>
);

export default ImageSection;
