import React from 'react';

import { Box } from '@chakra-ui/react';
import { H2, H4, H5 } from '../styles/HeadingStyles';

type Props = {
  sectionTitle: string;
  subTitle?: string;
  highlight?: boolean;
};

// Our super simple section title component used in templates, populated from CMS
const SectionTitle = ({ sectionTitle, subTitle, highlight }: Props) => (
  <Box flexBasis="100%" maxWidth="maxWidth" mx="auto" mb={[6]}>
    {highlight ? (
      <H5 as="h2" fontSize="6">
        {sectionTitle}
      </H5>
    ) : (
      <H2>{sectionTitle}</H2>
    )}

    {subTitle && (
      <H4 as="p" mb={[0]}>
        {subTitle}
      </H4>
    )}
  </Box>
);

export default SectionTitle;
