import React from 'react';
import { Box, chakra, useMediaQuery } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { H2 } from '../styles/HeadingStyles';

const Slider = chakra(Box, {
  baseStyle: {
    '.slide': {
      flex: '1 0 125px',
    },
    '@media only screen and (min-width: 600px)': {
      '@keyframes scroll': {
        '0%': {
          transform: 'translateX(0)',
        },
        '100%': {
          transform: 'translateX(calc(-250px * 22))',
        },
      },
      '.slideContainerColorLight': {
        '&:before, &:after': {
          background:
            'linearGradient( to right, #ffff 0%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      '.slideContainerColorDark': {
        '&:before, &:after': {
          background:
            'linear-gradient( to right, #f4f5f6 0%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      '.slideContainer': {
        overflow: 'hidden',
        position: 'relative',
        margin: 'auto',
        maxWidth: '1100px',
        '&:before, &:after': {
          content: '""',
          height: '100px',
          position: 'absolute',
          width: '100px',
          zIndex: '1',
        },
        '&:after': {
          right: '0',
          top: '0',
          transform: 'rotateZ(180deg)',
        },
        '&:before': {
          left: '0',
          top: '0',
        },
      },
      '.slideBox': {
        animation: 'scroll 40s linear infinite',
      },
      '.slide': {
        flex: 'none',
        width: '250px',
      },
    },
  },
});

type Props = {
  title: string;
  sectionColor?: string;
  clientLogos: [
    {
      id: string;
      logo: {
        alt: string;
        gatsbyImageData: IGatsbyImageData; // Generated by DatoCMS
      };
    }
  ];
};

/** Displays the logos of our clients. Gets images from dato CMS in the Clients model. */
const ClientLogos = ({ clientLogos, title, sectionColor }: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)');
  let logos;
  if (isMobile) {
    logos = clientLogos.slice(0, 10);
  } else {
    logos = clientLogos;
  }
  return (
    <Slider mb={[5, 3]} width="100%">
      <Box mb={[3, 6]} width="100%">
        {title && <H2>{title}</H2>}
      </Box>
      <div
        className={
          sectionColor === 'lightGreySection'
            ? 'slideContainerColorDark slideContainer'
            : 'slideContainerColorLight slideContainer'
        }
      >
        <Box
          alignItems="center"
          flexWrap={['wrap']}
          display="flex"
          mt={[4, 10]}
          className="slideBox"
          width={['none', `calc(250px * ${(logos.length + 1) * 2})`]}
        >
          {logos.map(({ logo, id }) => (
            <Box
              key={id}
              mb={[6, 20]}
              px={[2, 0]}
              pt={[4, 0]}
              className="slide"
              display={['inline-block']}
            >
              <GatsbyImage
                image={logo.gatsbyImageData}
                style={{ maxHeight: '70px' }}
                imgStyle={{ objectFit: 'contain' }}
                alt={logo.alt}
                loading="eager"
              />
            </Box>
          ))}
          {logos.map(({ logo, id }) => (
            <Box
              key={id}
              mb={[6, 20]}
              px={[2, 0]}
              pt={[4, 0]}
              className="slide"
              display={['none', 'inline-block']}
            >
              <GatsbyImage
                image={logo.gatsbyImageData}
                style={{ maxHeight: '70px' }}
                imgStyle={{ objectFit: 'contain' }}
                alt={logo.alt}
                loading="eager"
              />
            </Box>
          ))}
        </Box>
      </div>
    </Slider>
  );
};

export default ClientLogos;
