import React from 'react';
import { Box, Text, Flex, chakra } from '@chakra-ui/react';
import { H2 } from '../styles/HeadingStyles';

const Column = chakra(Box, {
  baseStyle: {
    flex: '1 1 300px',
  },
});

Column.defaultProps = {
  px: [5, 7],
  pb: [5, 0],
  mb: [7, 0],
};

// TODO I hate how i solved this. As a cms editor I should be able to select from an array of testimonials and then select which ones to shoe on a template page.

type Props = {
  heading: string;
  testimonial: string;
  reviewer: string;
  role: string;
  testimonial2?: string;
  reviewer2?: string;
  role2?: string;
};

/** Testimonial component. Shows two testimonials sidebyside. */
const Testimonial = ({
  heading,
  testimonial,
  reviewer,
  role,
  testimonial2,
  reviewer2,
  role2,
}: Props) => (
  <>
    <Box textAlign="center" margin="auto">
      <H2 fontSize={[3, 7]} lineHeight={[3, 7]} mb={['2.25rem']}>
        {heading}
      </H2>
    </Box>

    <Flex textAlign="center" flexWrap="wrap" px={[3, 7]} mb={[16]}>
      <Column>
        <Text mb={5}>"{testimonial}"</Text>
        <Text>
          <strong>{reviewer}</strong>
        </Text>
        <Text>
          <strong>{role}</strong>
        </Text>
      </Column>
      {testimonial2 && (
        <Column>
          <Text mb={5}>"{testimonial2}"</Text>
          <Text>
            <strong>{reviewer2}</strong>
          </Text>
          <Text>
            <strong>{role2}</strong>
          </Text>
        </Column>
      )}
    </Flex>
  </>
);

export default Testimonial;
