import React from 'react';
import { Box } from '@chakra-ui/react';
import Cal from './MeetingCalender';
import { H2 } from '../styles/HeadingStyles';

type Props = {
  /** Title of the form. Usually a Call to action */
  title: string;
  /** Url from Datocms found in hubspot */
  meetingUrl: string;
};

/** Component for Adding hubspot meeting calenders to a page */
const CalendarSection = ({ title, meetingUrl }: Props): JSX.Element => (
  <Box m="auto" maxWidth="800px" textAlign="center">
    <H2>{title}</H2>
    <Cal meetingUrl={meetingUrl} />
  </Box>
);

export default CalendarSection;
