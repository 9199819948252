import React from 'react';
import { Box, Button, chakra, Link } from '@chakra-ui/react';
import { H2, H4 } from '../styles/HeadingStyles';
import CtaLink from './CtaLink';

type Props = {
  /** The main title for the section */
  title: string;
  /** The subtitle for the section */
  subtitle?: string;
  /** Link for a call to action component */
  link: {
    slug: string;
  };
  /** Text which should be included on a button */
  buttonText: string;
  sectionColour?: string;
  notLocal?: boolean;
  externalLink?: string;
};

const ButtonStyle = chakra(Button, {
  baseStyle: {
    ':hover': {
      border: 'solid 1px white',
    },
    a: {
      ':hover': {
        textDecoration: 'none',
      },
    },
  },
});

/** A simple component for displaying a call to action section */
const Cta = ({
  title,
  buttonText,
  link,
  subtitle,
  sectionColour,
  notLocal,
  externalLink,
}: Props): JSX.Element => {
  if (sectionColour === 'darkSection') {
    return (
      <Box
        textAlign="center"
        maxWidth="maxWidth"
        mx="auto"
        mb={[8, '5.556rem']}
        px={[0, 16]}
      >
        <H2 mb={[3, 0]} color="white">
          {title}
        </H2>
        {subtitle && (
          <H4 as="p" color="white" mb={[7, 8]}>
            {subtitle}
          </H4>
        )}
        {!notLocal && (
          <CtaLink
            link={link}
            cta={buttonText}
            arialabel={buttonText}
            size="lg"
            variant="primary"
          />
        )}
        {notLocal && (
          <ButtonStyle px="6" py="2" size="lg" variant="primary">
            <Link sx={{ color: 'white' }} href={externalLink}>
              {buttonText}
            </Link>
          </ButtonStyle>
        )}
      </Box>
    );
  }
  return (
    <Box
      textAlign="center"
      maxWidth="maxWidth"
      mx="auto"
      mb={[8, '5.556rem']}
      px={[0, 16]}
    >
      <H2 mb={[3, 7]}>{title}</H2>
      <H4 as="p" mb={[7, 8]}>
        {subtitle}
      </H4>
      <CtaLink link={link} cta={buttonText} arialabel={buttonText} size="lg" />
    </Box>
  );
};

export default Cta;
