import React from 'react';
import { Box, chakra } from '@chakra-ui/react';

const TextContainer = chakra(Box, {
  baseStyle: {
    'ol li': {
      textAlign: 'left',
      fontSize: '1rem',
      marginLeft: '1.5rem',
    },
    ul: {
      textAlign: 'center',
    },
    'ul li': {
      textAlign: 'left',
      fontSize: '1rem',
      marginLeft: '1.5rem',
    },
    a: {
      color: 'primary',
      textDecoration: 'primary, underline',
    },
  },
});

type Props = {
  text: string;
};

/** A simple text component  */
const TextSection = ({ text }: Props) => (
  <TextContainer
    flexBasis="100%"
    textAlign="left"
    maxWidth="maxWidth"
    mb={[7, 8]}
  >
    <Box mb={['4rem']} fontSize={[2, 2, 2]}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
  </TextContainer>
);

export default TextSection;
