const logEvent = (event, details) => {
  if (typeof window !== `undefined`) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      details,
    });
  }
};

export default logEvent;
