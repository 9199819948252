import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { animate } from 'motion';
import VideoPlayer from './VideoPlayer';

/** Component for the featured vide section. Mainly included on the Feature page template */

type Props = {
  id: string;
  video: string;
  thumbnail: string;
  videoThumbnailTime?: string;
  title: string;
  captions?: string;
  createdAt: string;
  duration: string;
};

/** A component that uses the VideoPlayer to display videos and adds animations  */
const VideoSection = ({
  video,
  thumbnail,
  videoThumbnailTime,
  title,
  captions,
  createdAt,
  duration,
}: Props) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: [0.2, 1],
    // delay: 800,
    // triggerOnce: true,
  });

  const [isMobile, setIsMobile] = useState(false);

  const viewPortWidth =
    typeof window !== `undefined` ? window.innerWidth : null;

  useEffect(() => {
    viewPortWidth < 450 && setIsMobile(true); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView) {
      if (isMobile) {
        animate(
          '.video-container',
          { transform: 'scale(1)' },
          { duration: 0.6 }
        );
      } else {
        animate(
          '.video-container',
          { transform: 'scale(0.7)' },
          { duration: 0.6 }
        );
      }
    } else {
      animate('.video-container', { transform: 'scale(1)' }, { duration: 0.6 });
    }
  }, [inView, isMobile]);

  return (
    <div
      ref={ref}
      className={isMobile ? 'full-width' : undefined}
      style={{
        boxSizing: 'border-box',
        position: 'relative',
        width: '100vw',
        marginLeft: '-50vw',
        left: '50%',
        marginTop: isMobile ? '0px' : '-50px',
      }}
    >
      <div className="video-container">
        <VideoPlayer
          streamingUrl={video}
          thumbnailUrl={thumbnail}
          featuredMediaThumbTime={videoThumbnailTime}
          title={title}
          captions={captions}
          createdAt={createdAt}
          duration={duration}
          bookDemo
        />
      </div>
    </div>
  );
};

export default VideoSection;
