import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { chakra, Box, Heading } from '@chakra-ui/react';
import Avatar from './Avatar';
import { SubHeading, H3, Small } from '../styles/HeadingStyles';

const BioCard = chakra(Box, {
  baseStyle: {
    paddingTop: '1.125rem',
    flex: '1 1 400px',
    borderRadius: '4px',
    p: {
      marginBottom: '0',
    },
  },
});

type Props = {
  /** The image to display for the bio. SOurced from DatoCMS */
  image: {
    gatsbyImageData: IGatsbyImageData;
    alt: string;
  };
  /** The persons short biography */
  bio?: string;
  /** The persons Job Title */
  jobTitle: string;
  /** The persons name */
  name: string;
  /** The persons LinkedIn Profile Link */
  url: string;
};

/** A Component for displaying a bio inside a card. This is found on the about us page on the website. */
const Bio = ({ image, name, bio, jobTitle, url }: Props): JSX.Element => (
  <BioCard mx={[5, 7]} mb={[4, 7]}>
    <Box width="130px" mb={5}>
      {image && <Avatar image={image} />}
    </Box>

    <H3 mb={[3, 1]}>{name}</H3>
    <SubHeading mb={[8, 8]}>{jobTitle}</SubHeading>

    <Small fontWeight="normal">{bio}</Small>
    <a href={url} target="_blank" rel="noopener noreferrer">
      View {name}'s LinkedIn Profile
    </a>
  </BioCard>
);

export default Bio;
