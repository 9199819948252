import React from 'react';
import PropTypes from 'prop-types';
import { chakra } from '@chakra-ui/react';

const CardContainer = chakra('div', {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
});

type Props = {
  /** We pass through the card count here to try and make the grid show nicely irrespective of how many cards there are.  */
  cardCount?: number;
};

/** A simple card component here */
const Card: React.FC<Props> = ({ children, cardCount }) => (
  <CardContainer
    mb={[16]}
    mr={[0, 7]}
    // Change the width of the card depending on how many cards there are.
    sx={{
      flex: cardCount === 4 ? '1 1 400px' : '1 1 321px',
    }}
  >
    {children}
  </CardContainer>
);

Card.propTypes = {
  cardCount: PropTypes.number,
};

export default Card;
