import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Box } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import Card from './Card';
import { SubHeading } from '../styles/HeadingStyles';
import CtaLink from './CtaLink';

type Props = {
  block: {
    id: string;
    externalLink: string;
    externalLinkUrl: string;
    linkTo: {
      slug: string;
    };
    videoFile: {
      video: {
        streamingUrl: string;
      };
      url: string;
    };
    icon: {
      alt: string;
      gatsbyImageData: IGatsbyImageData;
    };
    text: string;
    title: string;
    linkCta: string;
  };
  cardCount: number;
};

/** A component used in Feature Pages */
const CardBlock = ({ block, cardCount }: Props) => (
  <Card key={block.id} cardCount={cardCount}>
    {block.videoFile && (
      <Box mb={[5, 7]}>
        <div>
          <ReactPlayer
            className="player"
            url={block.videoFile.video.streamingUrl}
            width="100%"
            height="100%"
            controls
          />
        </div>
      </Box>
    )}
    {block.icon && (
      <Box mb={[5, 7]} height="250px" margin="auto">
        <GatsbyImage image={block.icon.gatsbyImageData} alt={block.icon.alt} />
      </Box>
    )}
    <Box
      pt={['5']}
      sx={{
        borderTop: '1px solid #25404e',
      }}
    >
      <SubHeading as="h3" mb="4">
        {block.title}
      </SubHeading>
    </Box>
    <p>{block.text}</p>
    {block.linkCta && (
      <div style={{ marginTop: 'auto' }}>
        <CtaLink
          cta={block.linkCta}
          link={
            block?.externalLinkUrl
              ? { slug: block.externalLinkUrl, __typename: 'external' }
              : block?.linkTo
          }
          arialabel={`Learn more about ${block.title}`}
          mt="6"
          size="lg"
        />
      </div>
    )}
  </Card>
);

export default CardBlock;
